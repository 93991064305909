import React, {
  useCallback,
  useContext,
  useState,
} from "react";
import {SideNavigationBar, DropdownButton, DropdownItem, Accordion, Nav,} from "@shoutout-labs/shoutout-themes-enterprise";
import {faUsers, faCoins, faSetting, faMedal,faUser, faAddressCard,faChart} from "FaICIconMap";
import { useLocation, useHistory } from "react-router-dom";
import NavigationLink from "./NavigationLink";
import logo from "./../../assets/images/logo_full_blue.png";
import {  UserContext } from "Contexts";
import { AccessPermissionModules, AccessPermissionModuleNames } from "Data";

import "./Navigation.scss";


const CustomSidebarMainToggle = ({ eventKey, icon, onSelect }) => {
  const onClick = useCallback(() => {
    onSelect(eventKey);
  }, [eventKey, onSelect]);
  return (
    <SideNavigationBar.NavLink icon={icon} name={eventKey} onClick={onClick} />
  );
};

const Navigation = () => {
  const location = useLocation();
  const history = useHistory();
  // const { username, logout: userLogout} = useContext(UserContext);
  const {
    organization,
    onSelectRegion,
    selectedRegion: region,
    isAuthorizedForAction
  } = useContext(UserContext);

  const [activeEventKey, setActiveEventKey] = useState("");

  // const region = useMemo(() => {
  //   return organization.regions.find((item) => item._id === regionId) || null;
  // }, [organization, regionId]);

  const navList = [
    (isAuthorizedForAction(
      AccessPermissionModuleNames.TRANSACTION_SYNC_LOG,
      AccessPermissionModules[
          AccessPermissionModuleNames.TRANSACTION_SYNC_LOG
      ].actions.ListTransactionSyncLogs) &&
    {
      tab: "Transactions",
      path: "/",
      icon: faCoins,
    }),
    (isAuthorizedForAction(
      AccessPermissionModuleNames.MEMBER_SYNC_LOG,
      AccessPermissionModules[
          AccessPermissionModuleNames.MEMBER_SYNC_LOG
      ].actions.ListMemberSyncLogs) &&
    {
      tab: "Members",
      path: "/members",
      icon: faUsers,
    }),
    (isAuthorizedForAction(
      AccessPermissionModuleNames.CARD_SYNC_LOG,
      AccessPermissionModules[
          AccessPermissionModuleNames.CARD_SYNC_LOG
      ].actions.ListCardSyncLogs) &&
    {
      tab: "Cards",
      path: "/cards",
      icon: faAddressCard,
    }),
    (isAuthorizedForAction(
      AccessPermissionModuleNames.AFFINITY_TIER_MAPPING,
      AccessPermissionModules[
          AccessPermissionModuleNames.AFFINITY_TIER_MAPPING
      ].actions.ListAffinityTierMappings) &&
    {
      tab: "Shopper Levels",
      path: "/shopper-levels",
      icon: faMedal,
    }),
    {
      tab: "Configurations",
      path: "/configurations",
      icon: faSetting,
    },
    {
      tab: "Reports",
      path: "/reports",
      icon: faChart,
    },
    {
      tab: "Profile",
      path: "/profile",
      icon: faUser,
    },
  ];

  const navigateTo = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      history.push(e.currentTarget.getAttribute("href"));
    },
    [history]
  );

  const onSetActiveEventKey = useCallback(
    (eventKey) => {
      setActiveEventKey(eventKey !== activeEventKey ? eventKey : "");
    },
    [setActiveEventKey, activeEventKey]
  );

  return (
    // <div className="sidebar-wrapper bg-white">
    <div className="sidebar-wrapper">
      <SideNavigationBar
        className="side-navigation"
        logo={organization.organizationLogoImageUrl || logo}
      >
        {/* <div className="sidebar-container"> */}
        {/* <div className="side-navigation-nav-link pt-1">
            <Image className="logo" src={logo} alt="ShoutOUT" />
          </div> */}


          <DropdownButton
            data-testid="region-selection-dropdown"
            bsPrefix="btn w-100 region-selection-btn mb-3"
            title={
              region && (
                <>
                  {region.regionIconUrl && (
                    <img
                      src={region.regionIconUrl}
                      className="flags-img  rounded-circle mr-1 mb-1"
                      alt={region.regionName}
                    />
                  )}
                  <span className="mr-1">{region.regionName}</span>
                </>
              )
            }
            className=""
            onSelect={onSelectRegion}
          >
            {organization.regions.map(({ _id, regionIconUrl, regionName }) => {
              return (
                <DropdownItem
                  eventKey={_id}
                  key={_id}
                  className="region-selection"
                >
                  {region.regionIconUrl && (
                    <img
                      src={regionIconUrl}
                      className="flags-img rounded-circle  mr-2"
                      alt={regionName}
                    />
                  )}
                  {regionName}
                </DropdownItem>
              );
            })}
          </DropdownButton>

        {/* <NavLink
            to="/"
            className={`link dashboard text-${
              location.pathname === "/" ? "primary" : "dark"
            } `}
            activeClassName={location.pathname === "/" ? "active-link" : ""}
            id="side-nav-link-dashboard"
          >
            <FaIcon icon={faTachometerAlt} className="mx-1" />
            <span className="link-text">Dashboard</span>
          </NavLink> */}
        <Accordion activeKey={activeEventKey}>
          {navList.map((item) => {
            /* switch(item.tab) {
              case "CardsPage":
                return (
                    <NavigationLink
                        path={item.path}
                        tab={item.tab}
                        icon={item.icon}
                        activePath={location.pathname}
                    />
                );
                break;
              default:
                return (
                    <NavigationLink
                        path={item.path}
                        tab={item.tab}
                        icon={item.icon}
                        activePath={location.pathname}
                    />
                );
            }

            */

            if (item.secondaryLinks) {
              return (
                <div className="secondary-menu">
                  <CustomSidebarMainToggle
                    eventKey={item.tab}
                    onSelect={onSetActiveEventKey}
                    icon={item.icon}
                    activeKey={activeEventKey}
                  />
                  <Accordion.Collapse eventKey={item.tab}>
                    <div>
                      {item.secondaryLinks.map(({ tab, path }) => (
                        <Nav.Item>
                          <Nav.Link
                            href={path}
                            active={location.pathname === path}
                            onClick={navigateTo}
                          >
                            {tab}
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                    </div>
                  </Accordion.Collapse>
                </div>
              );
            }
            return (
              <NavigationLink
                path={item.path}
                tab={item.tab}
                icon={item.icon}
                activePath={location.pathname}
              />
            );
          })}
          {/* </div> */}
        </Accordion>
      </SideNavigationBar>
    </div>
  );
};

export default Navigation;
