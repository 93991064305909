import React, { useCallback, useContext} from 'react'
import BaseLayout from "./../../Layout/BaseLayout";
import { Button, Row, Col, Card, IcIcon, Avatar,Heading} from '@shoutout-labs/shoutout-themes-enterprise';
import {faEnvelope, faPhone } from 'FaICIconMap';
import { UserContext } from '../../Contexts/userContext';
import MyProfileEdit from './edit/MyProfileEdit';
import { useToggle } from './../../Hooks';

const MyProfile = () => {
    const { logout: userLogout, username, email, mobile,loadProfile } = useContext(UserContext);
    const [showEdit, toggleShowEdit] = useToggle(false);


    const logout = useCallback(() => {
        userLogout();
    }, [userLogout])

    // email, mobile, smsCreditsOnDemand, smsCreditsPlan, emailCreditsOnDemand, emailCreditsPlan, organization

    return (

        <BaseLayout
            topLeft={
                <Heading text ="Profile"/>
            }
            topRight={
                <Row noGutters={true}>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <Button variant="dark" size="sm" onClick={logout}>Logout</Button>
                    </Col>
                </Row>
            }
            bottom={
                <>
                    <Row noGutters={true} className="pt-2">
                        <Col lg={4} md={6} sm={12} xs={12} className="my-sm-3 mr-3" >
                            <Card className="h-100">
                                <Card.Body>
                                    <Row className="justify-content-between">
                                        <Col lg={3} md={6} sm={12} xs={12}>
                                            <Avatar
                                                name={username}
                                                size="64"
                                                color="#bebebe"
                                            // src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcS8gj-5Ib5eNrC80_EvUC03lMeuxwhWupOIAw&usqp=CAU"
                                            />
                                        </Col>
                                        <Col lg={9} md={6} sm={12} xs={12} className="text-right">
                                            <Card.Title className="text-muted">{username}</Card.Title>
                                            <div className="d-flex justify-content-end">
                                                {/* <Button className="mr-2" size="sm" variant="primary" onClick={toggleShowEdit}><IcIcon icon={faEdit} className="mr-2" />Edit</Button> */}
                                                {/* <Button size="sm" variant="danger"><IcIcon icon={faTrashAlt} className="mr-2" />Delete</Button> */}
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4} md={6} sm={12} xs={12} className="my-sm-3 ">
                            <Card className="h-100">
                                <Card.Body>
                                    <Row>
                                        <Col>
                                            <h6 className="mb-0 text-muted">Contact Info</h6>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6} md={6} sm={12} xs={12}>
                                            <small className="text-muted"><IcIcon icon={faEnvelope} className="mr-2" />Email</small> <br />
                                            <small className="text-primary">{email}</small>
                                        </Col>
                                        <Col lg={6} md={6} sm={12} xs={12}>
                                            <small className="text-muted"><IcIcon icon={faPhone} className="mr-2" />Phone</small><br />
                                            <small className="text-primary">{mobile}</small>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4} md={6} sm={12} xs={12} className="my-sm-3 ">

                        </Col>
                    </Row>
                    <MyProfileEdit show={showEdit} toggleEdit={toggleShowEdit} existingData={{ username: username, mobileNumber: mobile }} loadProfile={loadProfile}/>
                </>
            }
        />
    )
}

export default MyProfile
