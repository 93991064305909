import React, {
  useReducer,
  useEffect,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { getProfile, getOrganizationDetails, getRegions } from "../Services";
import { withRouter } from "react-router-dom";
import { KeycloakContext } from "./keycloakAuthServiceContext";
import { getValueFromObject, setFavicon, setSiteTitle } from "Utils";
import {
  AccessPermissionModuleNames,
  AccessPermissionModules,
  UserBoundaryType,
} from "Data";
import { toast } from "react-toastify";
import update from "immutability-helper";
const UserContext = React.createContext();

const UserContextActions = {
  UPDATE_USER_DATA: "updateUserData",
  SET_ORGANIZATION: "setOrganization",
  SET_IS_LOADING_ORGANIZATION: "setIsLoadingOrganization",
  SET_INIT_SYSTEM_ERROR_MESSAGE: "setInitSystemErrorMsg",
  SELECT_REGION: "setSelectRegion",
  SET_USER_STATE: "setUserState",
  // SET_SHOULD_RESET: "setShouldReset",
  SET_USER_CONFIG_LOADED: "setUserConfigLoaded",
  // SET_SYSTEM_MODULES: "setSystemModules",
  // SET_IS_SYSTEM_MODULE: "setIsSystemModule",
  SET_REGIONS: "setRegions",
};

const inititalUserPermissionPolicies = (enabledAll = true) =>
  Object.values(AccessPermissionModules).reduce(
    (result, { moduleName, actions }) => {
      result[moduleName] = Object.values(actions).reduce((r, item) => {
        r[item] = enabledAll;
        return r;
      }, {});
      return result;
    },
    {}
  );

const initialState = {
  boundary: UserBoundaryType.GLOBAL,
  boundaryMerchantId: "",
  regionId: "",
  selectedRegion: {}, //assumption: if user is a regional/merchant bounded, selected region will be set to that region
  organization: {
    organizationName: "",
    organizationLogoImageUrl: "",
    address: {
      street: "",
      city: "",
      zip: "",
    },
    regions: [],
  },
  isRunningSystemInit: true,
  systemInitFailMessage: null,
  config: {
    memberTableColumns: [
      "name",
      "contact",
      "points",
      "tier",
      "createdOn",
      "lastSeenOn",
    ],
  },
  userConfigLoaded: false,

  // isLoadingModules: true,
};

//
const reducer = (state, action) => {
  // console.debug("USER ACTION:", action);
  switch (action.type) {
    // case UserContextActions.LOGOUT: {
    //     // localStorage.removeItem("token");
    //     return { ...initialState, initComplete: true };
    // }
    // case UserContextActions.LOGIN: {

    //     // const { userRole, scopes } = action;

    //     // let userScopes = [];
    //     // if (Array.isArray(scopes)) {
    //     //     userScopes = Object.values([...ACCESS_CONTROL_DATA, ...scopes].reduce((result, {
    //     //         moduleId,
    //     //         ...rest
    //     //     }) => {
    //     //         result[moduleId] = {
    //     //             ...(result[moduleId] || {}),
    //     //             moduleId,
    //     //             ...rest
    //     //         };
    //     //         return result;
    //     //     }, {}))
    //     // } else if (userRole) {
    //     //     if (UserAccessTemplates[userRole]) {
    //     //         userScopes = UserAccessTemplates[userRole];
    //     //     } else {
    //     //         userScopes = UserAccessTemplates.user;
    //     //     }
    //     // }

    //     // const userScopeTransformed = userScopes.reduce((result, item) => {
    //     //     result[item.moduleId] = item;
    //     //     return result;
    //     // }, {})

    //     // return { ...state, isAuth: true, username: action.username, email: action.email, roles: action.roles, token: action.token, exp: action.exp, scopes: userScopeTransformed };
    //     return { ...state, isAuth: true };
    // }

    case UserContextActions.UPDATE_USER_DATA: {
      return {
        ...state,
        ...action.data,
        userConfigLoaded: true,
      };
    }
    // case UserContextActions.INIT_COMPLETE: {
    //     return { ...state, initComplete: true };
    // }

    case UserContextActions.SET_ORGANIZATION: {
      const updatedState = {
        ...state,
        organization: action.organization,
        isRunningSystemInit: false,
        systemInitFailMessage: null,
      };

      if (action.organization?.regions?.length > 0) {
        updatedState.regionId = action.organization.regions[0]._id;
        updatedState.selectedRegion = action.organization.regions[0];
      }
      return updatedState;
    }

    case UserContextActions.SET_INIT_SYSTEM_ERROR_MESSAGE: {
      return {
        ...state,
        systemInitFailMessage: action.message,
        isRunningSystemInit: false,
      };
    }

    case UserContextActions.SET_USER_CONFIG_LOADED: {
      return {
        ...state,
        userConfigLoaded: action.status,
      };
    }

    case UserContextActions.SELECT_REGION: {
      return {
        ...state,
        regionId: action.regionId,
        selectedRegion: state.organization.regions?.find(
          (item) => item._id === action.regionId
        ),
      };
    }
    case UserContextActions.SET_USER_STATE: {
      const { key, value, ...rest } = action;
      return {
        ...state,
        config: {
          ...state.config,
          [key]: value,
        },
        ...rest,
      };
    }

    // case UserContextActions.SET_SYSTEM_MODULES: {
    //   return {
    //     ...state,
    //     systemModules: action.modules,
    //     isLoadingModules: false,
    //   };
    // }
    // case UserContextActions.SET_IS_SYSTEM_MODULE: {
    //   return {
    //     ...state,
    //     isLoadingModules: action.status,
    //   };
    // }

    case UserContextActions.SET_REGIONS: {
      return update(state, {
        organization: {
          regions: { $set: action.regions },
        },
      });
    }
    default:
      return state;
  }
};

const constantMock = window.fetch;

const UserContextProvider = withRouter((props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { token, keycloakLogout, isAuth, user } = useContext(KeycloakContext);

  const userPermissionPolicies = useMemo(() => {
    let policies = inititalUserPermissionPolicies(
      state.boundary === UserBoundaryType.ROOT
    );

    if (state.userPermissions) {
      state.userPermissions.forEach((permissionItem = []) => {
        permissionItem.permissions?.forEach(({ group }) => {
          group?.policies?.forEach((policy) => {
            if (policies[policy.moduleName]) {
              policy.actions?.forEach((action) => {
                policies[policy.moduleName][action] = true;
              });
            }
          });
        });
      });
    }

    //TODO: Temporarily set to evaluate access control. Should remove
    // policies[AccessPermissionModuleNames.ORGANIZATION][
    //       AccessPermissionModules[
    //           AccessPermissionModuleNames.ORGANIZATION
    //       ].actions.GetOrganization
    //   ] = true;

    // policies[AccessPermissionModuleNames.GROUPS][AccessPermissionModules[AccessPermissionModuleNames.GROUPS].actions.UpdateGroup]=true;
    return policies;
  }, [state.userPermissions, state.boundary]);

  const logout = useCallback(() => {
    window.fetch = constantMock;
    keycloakLogout();
  }, [keycloakLogout]);

  const isAuthorizedForAction = useCallback(
    (moduleName, actionName) => {
      if (actionName) {
        return userPermissionPolicies[moduleName][actionName];
      }

      return getValueFromObject(userPermissionPolicies, moduleName) || false;
    },
    [userPermissionPolicies]
  );

  const loadOrganization = useCallback(async () => {
    if (
      isAuthorizedForAction(
        AccessPermissionModuleNames.ORGANIZATION,
        AccessPermissionModules[AccessPermissionModuleNames.ORGANIZATION]
          .actions.GetOrganization
      )
    ) {
      try {
        const organizationResponse = await getOrganizationDetails();
        dispatch({
          type: UserContextActions.SET_ORGANIZATION,
          organization: organizationResponse,
        });

        if (organizationResponse.organizationFavicon) {
          setFavicon(organizationResponse.organizationFavicon);
        }
        if (organizationResponse.organizationAppTitle) {
          setSiteTitle(organizationResponse.organizationAppTitle);
        }
      } catch (e) {
        dispatch({
          type: UserContextActions.SET_INIT_SYSTEM_ERROR_MESSAGE,
          message:
            "Could not load your organization details. Please retry. If issue persist, contact the support.",
        });
      }
    } else {
      dispatch({
        type: UserContextActions.SET_INIT_SYSTEM_ERROR_MESSAGE,
        message:
          "You do not have get organization details permission. Dashboard can not load without this permission",
      });
    }
  }, [dispatch, isAuthorizedForAction]);
  // const loadModules = useCallback(async () => {
  //     if (
  //       isAuthorizedForAction(
  //           AccessPermissionModuleNames.MODULES,
  //           AccessPermissionModules[AccessPermissionModuleNames.MODULES]
  //               .actions.ListModules
  //       )
  //     ) {
  //       try {
  //         const modules = await getModules();
  //         dispatch({
  //           type: UserContextActions.SET_SYSTEM_MODULES,
  //           modules: modules.items || [],
  //         });
  //       } catch (e) {
  //         dispatch({
  //           type: UserContextActions.SET_IS_SYSTEM_MODULE,
  //           status: false,
  //         });
  //       }
  //     }else{
  //       dispatch({
  //         type: UserContextActions.SET_IS_SYSTEM_MODULE,
  //         status: false,
  //       });
  //     }
  //   }, [dispatch, isAuthorizedForAction]);

  const loadProfile = useCallback(async () => {
    try {
      const profileResponse = await getProfile(user.id);
      dispatch({
        type: UserContextActions.UPDATE_USER_DATA,
        data: profileResponse,
      });
    } catch (e) {}
  }, [dispatch, user]);

  const login = useCallback(
    (token) => {
      window.fetch = async function () {
        // Get the parameter in arguments
        // Intercept the parameter here
        const body = arguments[1];
        if (body.headers["x-auth"]) {
          delete body.headers["x-auth"];
          // if (isTokenTimeNotExpired(exp)) {
          body.headers["Authorization"] = "Bearer " + token;
          // } else {
          //     logout();
          //     history.push("/login");
          //     return;
          // }
        }

        if (body.headers["x-auth-scope"]) {
          if (!isAuthorizedForAction(body.headers["x-auth-scope"])) {
            toast.error(
              `You are not authorized to perform the action: ${body.headers["x-auth-scope"]}`
            );
            return Promise.reject({ status: 403 });
          }
          delete body.headers["x-auth-scope"];
        }

        const response = await constantMock.apply(this, arguments);
        //TODO: [MLS-1243] Do not logout for 403
        if (response.status === 401 || response.status === 403) {
          dispatch({
            type: UserContextActions.SET_INIT_SYSTEM_ERROR_MESSAGE,
            message:
              "You are not authorized to perform certain actions or your session is expired. Login you out in few seconds...",
          });
          //token expired
          setTimeout(() => {
            logout();
          }, 3000);

          // history.push("/login");
        }

        return response;
      };
    },
    [logout, isAuthorizedForAction]
  );

  const onSelectRegion = useCallback(
    (regionId) => {
      dispatch({
        type: UserContextActions.SELECT_REGION,
        regionId: regionId,
      });
    },
    [dispatch]
  );

  const loadRegions = useCallback(async () => {
    try {
      const regionResponse = await getRegions({});
      dispatch({
        type: UserContextActions.SET_REGIONS,
        regions: regionResponse.items || [],
      });
    } catch (error) {
      console.error(error);
      toast.error("Couldn't load the regions");
    }
  }, [dispatch]);
  // const setShouldReset = useCallback(() => {
  //       dispatch({type: UserContextActions.SET_SHOULD_RESET});
  //     },
  //     [dispatch]
  // );
  useEffect(() => {
    (async () => {
      if (isAuth) {
        await loadProfile();
      }
    })();
    // eslint-disable-next-line
  }, [isAuth]);

  useEffect(() => {
    (() => {
      if (state.userConfigLoaded) {
        login(token);
        loadOrganization();
      }
    })();
    // eslint-disable-next-line
  }, [state.userConfigLoaded]);

  // useEffect(() => {
  //     const queryParams = queryString.parse(props.location.search);
  //     if (queryParams['token']) {
  //         const { token } = queryParams;
  //         login(token);
  //         history.push("/");

  //     } else {
  //         const token = localStorage.getItem('token');
  //         if (token) {
  //             login(token);
  //         }
  //     }
  //     dispatch({ type: UserContextActions.INIT_COMPLETE });
  // }, [history, props.location.search]);

  useEffect(() => {
    if (token) {
      login(token);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, userPermissionPolicies]);

  const value = {
    ...state,
    isAuth,
    username: user?.username,
    email: user?.email,
    logout,
    loadProfile,
    onSelectRegion,

    // setShouldReset,
    loadOrganization,
    loadRegions,
    isAuthorizedForAction,
  };

  // console.debug("USER CONTEXT:", value,userPermissionPolicies);

  return (
    <UserContext.Provider value={value}>{props.children}</UserContext.Provider>
  );
});

const UserContextConsumer = UserContext.Consumer;

export {
  UserContext,
  UserContextProvider,
  UserContextConsumer,
  UserContextActions,
};
