import React from "react";

const BaseLayout = ({ topLeft = null, topRight = null, bottom ,containerClassName=""}) => {
  return (
    <div className={`d-flex flex-column flex-fill ${containerClassName}`}>
      {topLeft && (
        <div className="d-flex flex-row justify-content-between align-items-center mb-2">
          <div className="mr-2" >

            {topLeft}

          </div>
          <div className="ml-auto">
            {topRight && (
              <>
                {topRight}
              </> 
            )}
          </div>
        </div>
      )}
      {bottom}
    </div>
  );
};

export default BaseLayout;
