const ShopperLevelStatus = {
    SUCCESS: "SUCCESS",
    RESOLVED: "RESOLVED",
    PENDING: "PENDING",
    DISCARDED: "DISCARDED",
    FAILED: "FAILED",
    all:""
};

export {
    ShopperLevelStatus
}
