import React from 'react';
import './Drawer.css';

const Drawer = ({children,show,width}) => {
    return (
        <div className={`drawer ${show&&'show'}`} style={{width}}>
            {children}
        </div>
    )
}
export default Drawer;