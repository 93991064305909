const PermissionModules = {
   AFFINITY_TIER_MAPPING : "AffinityTierMapping",
   CARD_SYNC_LOG : "CardSyncLog",
   MEMBER_SYNC_LOG : "MemberSyncLog",
   SYNC_CLUSTER : "SyncCluster",
   TRANSACTION_SYNC_LOG : "TransactionSyncLog",
   MODULES: "Module",
   ORGANIZATION: "Organization",
   AFFINITY_GROUPS: "AffinityGroup",
   TIER: "Tier",
   MERCHANT: "Merchant",
   USERS: "Users"
};

const PermissionModulesDetails = {
    [PermissionModules.AFFINITY_TIER_MAPPING]: {
        moduleName: "AffinityTierMapping",
        moduleDescription: "Affinity groups and tiers mapping module",
        actions: {
            ListAffinityTierMappings: "ListAffinityTierMappings",
            GetAffinityTierMapping: "GetAffinityTierMapping",
            UpdateAffinityTierMapping: "UpdateAffinityTierMapping"
        },
    },
    [PermissionModules.CARD_SYNC_LOG]: {
        moduleName: "CardSyncLog",
        moduleDescription: "Card module",
        actions: {
            ListCardSyncLogs: "ListCardSyncLogs",
            GetCardSyncLog: "GetCardSyncLog",
            UpdateCardSyncLog: "UpdateCardSyncLog"
        },
    },
    [PermissionModules.MEMBER_SYNC_LOG]: {
        moduleName: "MemberSyncLog",
        moduleDescription: "Member module",
        actions: {
            ListMemberSyncLogs: "ListMemberSyncLogs",
            GetMemberSyncLog: "GetMemberSyncLog",
            UpdateMemberSyncLog: "UpdateMemberSyncLog"
        },
    },
    [PermissionModules.SYNC_CLUSTER]: {
        moduleName: "SyncCluster",
        moduleDescription: "Sync cluster module",
        actions: {
            ListSyncClusters: "ListSyncClusters",
            GetSyncCluster: "GetSyncCluster",
            CreateSyncCluster: "CreateSyncCluster",
            UpdateSyncCluster: "UpdateSyncCluster"
        },
    },
    [PermissionModules.TRANSACTION_SYNC_LOG]: {
        moduleName: "TransactionSyncLog",
        moduleDescription: "Transactions module",
        actions: {
            ListTransactionSyncLogs: "ListTransactionSyncLogs",
            GetTransactionSyncLog: "GetTransactionSyncLog",
            UpdateTransactionSyncLog: "UpdateTransactionSyncLog"
        },
    },
    [PermissionModules.MODULES]: {
        moduleName: "Module",
        moduleDescription: "System modules",
        actions: {
            ListModules: "ListModules",
            CreateModule: "CreateModule",
            UpdateModule: "UpdateModule",
        },
    },
    [PermissionModules.ORGANIZATION]: {
        moduleName: "Organization",
        moduleDescription: "Organization management module",
        actions: {
            CreateOrganization: "CreateOrganization",
            GetOrganization: "GetOrganization",
            UpdateOrganization: "UpdateOrganization",
        },
    },
    [PermissionModules.AFFINITY_GROUPS]: {
        moduleName: "AffinityGroup",
        moduleDescription: "Affinity group management module",
        actions: {
            ListAffinityGroups: "ListAffinityGroups",
            GetAffinityGroup: "GetAffinityGroup",
            CreateAffinityGroup: "CreateAffinityGroup",
            UpdateAffinityGroup: "UpdateAffinityGroup",
            DeleteAffinityGroup: "DeleteAffinityGroup",
        },
    },
    [PermissionModules.MERCHANT]: {
        moduleName: "Merchant",
        moduleDescription: "Merchant management module",
        actions: {
            ListMerchants: "ListMerchants",
            GetMerchant: "GetMerchant",
            CreateMerchant: "CreateMerchant",
            UpdateMerchant: "UpdateMerchant",
            DeleteMerchant: "DeleteMerchant",
        },
    },
    [PermissionModules.TIER]: {
        moduleName: "Tier",
        moduleDescription: "Tier modules",
        actions: {
            ListTiers: "ListTiers",
            CreateTier: "CreateTier",
            UpdateTier: "UpdateTier",
            DeleteTier: "DeleteTier",
        },
    },
    [PermissionModules.USERS]: {
        moduleName: "Users",
        moduleDescription: "System user management module",
        actions: {
            CreateUser: "CreateUser",
            GetUser: "GetUser",
            ListUsers: "ListUsers",
            UpdateUser: "UpdateUser",
            DeleteUser: "DeleteUser",
            ResetPassword: "ResetPassword",
        },
    }
}

export default PermissionModulesDetails;

export { PermissionModules as AccessPermissionModuleNames };