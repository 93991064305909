import { fetchGet, jsonToQueryParam } from "./CommonServiceUtils";
import Constants from "../Constants";

const BASE_URL = `${Constants.BASE_URL}loyaltyservice/regions`;

const getRegions = ({ limit = 500, skip = 0 }) => {
  return fetchGet(`${BASE_URL}?${jsonToQueryParam({ limit, skip })}`);
};

export { getRegions };
