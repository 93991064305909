// TODO: Seems these services are not used in anywhere here. Confirm and delete the file

import { fetchGet, jsonToQueryParam ,
   fetchPut, 
   fetchPost, 
   fetchDelete,
   fetchPostMultipart
} from './CommonServiceUtils';
import Constants from '../Constants';
const BASE_URL = `${Constants.BASE_URL}loyaltyservice/`;

// const getTransactions = (queryObj) => {
//     //Query params : limit, skip, merchantId
//     return fetchGet(`${BASE_URL}transactions?${jsonToQueryParam(queryObj)}`);
// }

const getInvalidTransactions = (queryObj) => {
    //Query params : limit, skip, merchantId, status, importJobId, subType
    return fetchGet(`${BASE_URL}stagedtransactions?${jsonToQueryParam(queryObj)}`);
}

const archiveInvalidTransaction = (id) => {
    // TODO: Test the path once backend ready
    return fetchDelete(BASE_URL + "stagedtransactions/" + id);
}

const linkLoyaltyCardToTransaction = (id, payload) => {
  
    return fetchPut(`${BASE_URL}stagedtransactions/${id}`, payload);
}

const getImportTransactions = (queryObj) => {

    return fetchGet(`${BASE_URL}transactionimportjobs?${jsonToQueryParam(queryObj)}`);
}

const importTransactionFileUpload = (queryObj, fileFormData)=> {
    return fetchPostMultipart(`${BASE_URL}transactionimportjobs/uploadfile?${jsonToQueryParam(queryObj)}`, fileFormData);
}

const importTransaction = (payload) => {
   
    return fetchPost(BASE_URL + "transactionimportjobs" , payload);
}

export { 
    // getTransactions, 
    getInvalidTransactions,
    archiveInvalidTransaction,
    linkLoyaltyCardToTransaction ,
    getImportTransactions,
    importTransaction,
    importTransactionFileUpload
}