import {fetchGet, jsonToQueryParam} from './CommonServiceUtils';
import Constants from '../Constants';
const BASE_URL = `${Constants.SYNC_API_BASE_URL}datasyncservice/reports/`;


const getSMSTransactions= (queryObj) => {
    return fetchGet(`${BASE_URL}smstransactions?${jsonToQueryParam(queryObj)}`);
}

const getSMSBalance= (queryObj) => {
    return fetchGet(`${BASE_URL}smsbalance?${jsonToQueryParam(queryObj)}`);
}

export {
    getSMSTransactions,
    getSMSBalance,
}