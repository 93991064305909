const TransactionStatus = {
  VALID: "VALID",
  INVALID: "INVALID",
  PENDING:"PENDING"
};

const SyncStatus = {
  PENDING: "PENDING",
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
  DISCARDED: "DISCARDED",
};

const InvalidTransactionStatusVariant = {
  PENDING_ACTION: "warning",
  RESOLVED: "success",
  DISCARDED: "info",
  FAILED: "danger",
};
const StatusSelector = {
  all: "",
  PENDING: "PENDING",
  SUCCESS: "SUCCESS",
  INVALID: "FAILED",
  DISCARDED: "DISCARDED",
};

const ErrorTypes = {
  MEMBER_NOT_FOUND: "MEMBER_NOT_FOUND",
  INVALID_CARD: "INVALID_CARD",
  INVALID_TRANSACTION_TYPE: "INVALID_TRANSACTION_TYPE",
  INVALID_POINTS_AMOUNT: "INVALID_POINTS_AMOUNT",
  TOTALIZER_NOT_FOUND: "TOTALIZER_NOT_FOUND",
  TOTALIZER_MAP_NOT_CONFIGURED: "TOTALIZER_MAP_NOT_CONFIGURED",
};
export {
  TransactionStatus,
  SyncStatus,
  InvalidTransactionStatusVariant,
  StatusSelector,
  ErrorTypes,
};
