import { useState, useCallback } from "react";
import PropTypes from "prop-types";

const useOnChangeInput = (props) => {
  const [state, setState] = useState(props);

  return [
    state,
    useCallback((e) => {
      setState(e.target.value);
    }, []),
    useCallback((value) => {
      setState(value||props);
    },[props]),
  ];
};

useOnChangeInput.defaultProps = {
  props: false,
};
useOnChangeInput.propTypes = {
  /**
   * Initial value
   */
  props: PropTypes.string,

  /**
   * useOnChangeInput(initialValue,onChangeCallback,reset);
   */
};
export default useOnChangeInput;
