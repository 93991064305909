import {  fetchGet } from "./CommonServiceUtils";
import Constants from "../Constants";
import { AccessPermissionModules, AccessPermissionModuleNames } from "Data";
import { getPermissionPath } from "Utils";

const BASE_URL = `${Constants.BASE_URL}loyaltyservice/organizations`;

const getOrganizationDetails = () => {
  return fetchGet(
      BASE_URL,
      true,
      getPermissionPath(
          AccessPermissionModuleNames.ORGANIZATION,
          AccessPermissionModules[AccessPermissionModuleNames.ORGANIZATION]
              .actions.GetOrganization
      )
  );
};

export { getOrganizationDetails };
