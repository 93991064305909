import { fetchGet, fetchPut } from "./CommonServiceUtils";
import Constants from "../Constants";
import { getPermissionPath } from "Utils";
import {
  AccessPermissionModules,
  AccessPermissionModuleNames,
} from "Data";

const BASE_URL = `${Constants.BASE_URL}identityservice/`;

const getModules = () => {
  return fetchGet(
    `${BASE_URL}modules`,
    true,
    getPermissionPath(
      AccessPermissionModuleNames.MODULES,
      AccessPermissionModules[AccessPermissionModuleNames.MODULES].actions
          .ListModules
    )
  );
};

const getProfile = () => {
  return fetchGet(
    `${BASE_URL}accounts/profile`,
    true
  ); 
};

const updateProfile = (id, data) => {
  return fetchPut(
    `${BASE_URL}users/${id}`, data, 
    true,
    getPermissionPath(
      AccessPermissionModuleNames.USERS,
      AccessPermissionModules[AccessPermissionModuleNames.USERS].actions
          .UpdateUser
    )
  );
};

export { getModules, getProfile, updateProfile };
