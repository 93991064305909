import React, { useEffect } from "react";
// To do remove the default and customize a BS 4.5 Stylesheet for the theme.
//import '@shoutout-labs/shoutout-themes-enterprise/es/themes/default/bootstrap.min.css';
import "@shoutout-labs/shoutout-themes-enterprise/es/themes/enterprise-loyalty/bootstrap.min.css";
import { Switch } from "react-router-dom";
import { UserContextProvider } from "./Contexts/userContext";
import { DataContextProvider } from "./Contexts/dataContext";
import { KeycloakContextProvider } from "./Contexts/keycloakAuthServiceContext";
import Home from "./Components/home/Home";
import LoginPage from "./Components/accounts/login/Login";
import AuthRoute from "./AuthRoute";
import CustomRoute from "./CustomRoute";
import { datadogRum } from "@datadog/browser-rum";
import { ToastContainer, Slide } from "react-toastify";
import AppContext from "AppContext";
import "react-toastify/dist/ReactToastify.min.css";
import "./App.css";

const App = () => {
  useEffect(() => {
    datadogRum.init({
      sampleRate: 100,
      trackInteractions: true,
      defaultPrivacyLevel: "mask-user-input",
      ...AppContext.dataDogConfig
    });

    datadogRum.startSessionReplayRecording();

    return ()=>datadogRum.stopSessionReplayRecording();
  }, []);
  return (
    <div className="App h-100">
      <ToastContainer
        position="top-center"
        transition={Slide}
        theme="colored"
        pauseOnFocusLoss={false}
      />
      <KeycloakContextProvider>
        <UserContextProvider>
          <DataContextProvider>
            <Switch>
              <CustomRoute
                name="LoginPage"
                exact
                path="/login"
                component={LoginPage}
              />
              <AuthRoute path="/" component={Home} />
            </Switch>
          </DataContextProvider>
        </UserContextProvider>
      </KeycloakContextProvider>
    </div>
  );
};

export default App;
