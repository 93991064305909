import React, { useState, useCallback, useEffect } from 'react';
import Drawer from './../../utils/drawer/Drawer';
import { Button, Form } from '@shoutout-labs/shoutout-themes-enterprise';
import { updateProfile } from './../../../Services';

const MyProfileEdit = ({ show, toggleEdit, existingData, loadProfile }) => {

    const [name, setName] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        if (existingData) {
            setName(existingData.username);
            setMobileNumber(existingData.mobileNumber);
        }
    }, [existingData])

    const onChangeName = useCallback((e) => {
        setName(e.target.value);
    }, [setName])
    const onMobileNumberChange = useCallback((status, value, countryData, number,) => {
        const mobile = number.replace(/[^0-9]/gi, '');
        setMobileNumber(mobile);
    }, [setMobileNumber])

    const onSave = useCallback(async () => {
        try {
            setIsSaving(true);
            const updateProfileResponse = await updateProfile({ username: name, mobileNumber });
            loadProfile(updateProfileResponse);
            setMobileNumber("");
            setName("")
            setIsSaving(false);
        } catch (e) {

            setIsSaving(false);
        }
    }, [setIsSaving, name, mobileNumber, loadProfile,setMobileNumber,setName])
    return (
        <Drawer
            placement="right"
            show={show}
            width={400}
        >
            <Form>
                <Form.Group controlId="name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control name="name" type="text" placeholder="Sam" onChange={onChangeName} value={name} />
                </Form.Group>
                <Form.Group controlId="mobile">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.MobileNumberInput defaultCountry={'lk'}
                        onPhoneNumberBlur={onMobileNumberChange} onPhoneNumberChange={onMobileNumberChange}
                        format={true} formatOnInit={true} defaultValue={mobileNumber}
                    />
                </Form.Group>
                <div className="text-right">
                    <Button onClick={toggleEdit} type="button" variant="danger" disabled={isSaving} className="mr-2">Cancel</Button>
                    <Button onClick={onSave} type="button" variant="success" disabled={isSaving}>{isSaving ? "Saving..." : "Save"}</Button>

                </div>
            </Form>
        </Drawer>
    )
}
export default MyProfileEdit;