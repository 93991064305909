const CardStatus ={
    SUCCESS: "SUCCESS",
    RESOLVED: "RESOLVED",
    DISCARDED: "DISCARDED",
    PENDING:"PENDING",
    FAILED: "FAILED",
    all:""
}

export {
    CardStatus
}
