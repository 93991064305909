import ShoutOUTLogo from "./assets/images/shoutout_logo.png";
const DefaultContext = {
  logo: ShoutOUTLogo,
  //   keycloakConfig: {
  //     "realm": process.env.REACT_APP_KEYCLOAK_REALM,
  //     "auth-server-url": process.env.REACT_APP_KEYCLOAK_AUTH_SERVER_URL,
  //     // "ssl-required": process.env.REACT_APP_KEYCLOAK_SSL_REQUIRED||true,
  //     "clientId": process.env.REACT_APP_KEYCLOAK_RESOURCE,
  //     "resource": process.env.REACT_APP_KEYCLOAK_RESOURCE,
  //     // "public-client": true,
  //     // "confidential-port": 0,
  //   },
  keycloakConfig: `${process.env.PUBLIC_URL}/assets/config/${process.env.REACT_APP_KEYCLOAK_FILE_NAME}`,
  baseUrl:
    process.env.REACT_APP_API_BASE_URL ||
    "https://betaenterprise.api.topnotch.club/api/",
  syncApiBaseUrl:process.env.REACT_APP_SYNC_API_BASE_URL||"https://syncapi.beta-loyalty.massystores.com/api/",
  dataDogConfig: {
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: process.env.REACT_APP_DATADOG_SITE,
    service: process.env.REACT_APP_DATADOG_SERVICE,
    env: process.env.REACT_APP_DATADOG_ENV,
    version: process.env.REACT_APP_VERSION||"1.0.0"
  },
};


export default DefaultContext;
