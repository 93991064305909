// const userPermissions = [
//   // {
//   //   _id:"1",
//   //   regionId: "6137a9fff48b8eb1845c78cf",
//   //   userId: "613dfc56eac5369848619fe0",
//   //   permissions: [
//   //     {
//   //       merchantId: "614c2e706418e0d2af629dd6",
//   //       merchantLocationIds: ["613bc845c89bf19c02d3ee4f"],
//   //       groupIds: ["614dc3c4e5595e3c88e1ea61"],
//   //     },
//   //   ],
//   // },
//   // {
//   //   _id:"2",
//   //   regionId: "613ed7b184f388780ad0d769",
//   //   userId: "613dfc56eac5369848619fe0",
//   //   permissions: [
//   //     {
//   //       merchantId: "614c2e706418e0d2af629dd6",
//   //       merchantLocationIds: ["614c2e9756b9b032ff816cf7"],
//   //       groupIds: ["string"],
//   //     },
//   //   ],
//   // },
// ];

import {MemberStatus, ShopperLevelStatus, TransactionStatus, TransactionTypes} from "Data";

// const rewardLogisticsData = {
//   items: [
//     {
//       _id: "reward_1",
//       transactionId : "transaction_1",
//       loyaltyId : "loyalty_1",
//       batchId: "batch_1",
//       rewardName : "Reward 1",
//       pickUpLoactionId : "location_28",
//       pickUpLocation : "Location 28",
//       itemCount: 10,
//       createdOn: "2021-10-21T14:25:17.510Z",
//       status: "PENDING"
//     },
//     {
//       _id: "reward_2",
//       transactionId : "transaction_2",
//       loyaltyId : "loyalty_2",
//       batchId: "batch_2",
//       rewardName : "Reward 2",
//       pickUpLoactionId : "location_28",
//       pickUpLocation : "Location 28",
//       itemCount: 10,
//       createdOn: "2021-10-21T14:25:17.510Z",
//       status: "PENDING"
//     },
//     {
//       _id: "reward_3",
//       transactionId : "transaction_3",
//       loyaltyId : "loyalty_3",
//       batchId: "batch_3",
//       rewardName : "Reward 3",
//       pickUpLoactionId : "location_28",
//       pickUpLocation : "Location 28",
//       itemCount: 10,
//       createdOn: "2021-10-21T14:25:17.510Z",
//       status: "PENDING"
//     },
//     {
//       _id: "reward_4",
//       transactionId : "transaction_4",
//       loyaltyId : "loyalty_4",
//       batchId: "batch_1",
//       rewardName : "Reward 4",
//       pickUpLoactionId : "location_28",
//       pickUpLocation : "Location 28",
//       itemCount: 10,
//       createdOn: "2021-10-21T14:25:17.510Z",
//       status: "PROCESSING"
//     },
//     {
//       _id: "reward_5",
//       transactionId : "transaction_5",
//       loyaltyId : "loyalty_5",
//       batchId: "batch_2",
//       rewardName : "Reward 5",
//       pickUpLoactionId : "location_25",
//       pickUpLocation : "Location 25",
//       itemCount: 10,
//       createdOn: "2021-10-21T14:25:17.510Z",
//       status: "PROCESSING"
//     },
//     {
//       _id: "reward_6",
//       transactionId : "transaction_6",
//       loyaltyId : "loyalty_6",
//       batchId: "batch_2",
//       rewardName : "Reward 6",
//       pickUpLoactionId : "location_21",
//       pickUpLocation : "Location 21",
//       itemCount: 10,
//       createdOn: "2021-10-21T14:25:17.510Z",
//       status: "PROCESSING"
//     },
//     {
//       _id: "reward_7",
//       transactionId : "transaction_7",
//       loyaltyId : "loyalty_7",
//       batchId: "batch_2",
//       rewardName : "Reward 7",
//       pickUpLoactionId : "location_20",
//       pickUpLocation : "Location 20",
//       itemCount: 10,
//       createdOn: "2021-10-21T14:25:17.510Z",
//       status: "DISPATCHED"
//     },
//     {
//       _id: "reward_8",
//       transactionId : "transaction_8",
//       loyaltyId : "loyalty_8",
//       batchId: "batch_1",
//       rewardName : "Reward 8",
//       pickUpLoactionId : "location_26",
//       pickUpLocation : "Location 26",
//       itemCount: 10,
//       createdOn: "2021-10-21T14:25:17.510Z",
//       status: "DISPATCHED"
//     },
//     {
//       _id: "reward_9",
//       transactionId : "transaction_9",
//       loyaltyId : "loyalty_9",
//       batchId: "batch_1",
//       rewardName : "Reward 9",
//       pickUpLoactionId : "location_22",
//       pickUpLocation : "Location 22",
//       itemCount: 10,
//       createdOn: "2021-10-21T14:25:17.510Z",
//       status: "DISPATCHED"
//     },
//     {
//       _id: "reward_10",
//       transactionId : "transaction_10",
//       loyaltyId : "loyalty_10",
//       batchId: "batch_1",
//       rewardName : "Reward 10",
//       pickUpLoactionId : "location_20",
//       pickUpLocation : "Location 20",
//       itemCount: 10,
//       createdOn: "2021-10-21T14:25:17.510Z",
//       status: "COMPLETED"
//     },
//     {
//       _id: "reward_11",
//       transactionId : "transaction_11",
//       loyaltyId : "loyalty_11",
//       batchId: "batch_1",
//       rewardName : "Reward 11",
//       pickUpLoactionId : "location_20",
//       pickUpLocation : "Location 20",
//       itemCount: 10,
//       createdOn: "2021-10-21T14:25:17.510Z",
//       status: "COMPLETED"
//     },
//     {
//       _id: "reward_12",
//       transactionId : "transaction_12",
//       loyaltyId : "loyalty_12",
//       batchId: "batch_2",
//       rewardName : "Reward 12",
//       pickUpLoactionId : "location_21",
//       pickUpLocation : "Location 21",
//       itemCount: 10,
//       createdOn: "2021-10-21T14:25:17.510Z",
//       status: "COMPLETED"
//     },
//     {
//       _id: "reward_13",
//       transactionId : "transaction_13",
//       loyaltyId : "loyalty_13",
//       batchId: "batch_3",
//       rewardName : "Reward 13",
//       pickUpLoactionId : "location_3",
//       pickUpLocation : "Location 3",
//       itemCount: 10,
//       createdOn: "2021-10-21T14:25:17.510Z",
//       status: "FAILED"
//     },
//     {
//       _id: "reward_14",
//       transactionId : "transaction_14",
//       loyaltyId : "loyalty_14",
//       batchId: "batch_3",
//       rewardName : "Reward 14",
//       pickUpLoactionId : "location_8",
//       pickUpLocation : "Location 8",
//       itemCount: 10,
//       createdOn: "2021-10-21T14:25:17.510Z",
//       status: "FAILED"
//     },
//     {
//       _id: "reward_15",
//       transactionId : "transaction_15",
//       loyaltyId : "loyalty_15",
//       batchId: "batch_1",
//       rewardName : "Reward 15",
//       pickUpLoactionId : "location_2",
//       pickUpLocation : "Location 2",
//       itemCount: 10,
//       createdOn: "2021-10-21T14:25:17.510Z",
//       status: "FAILED"
//     },
//   ],
//   total: 15
// };

// // const notificationSettings=  {email:"loyality@massystores.com",Address:"MassyLoyalty"};

const mockGet = (data, failMockEnabled = false) => {
  return new Promise((r, reject) => {
    setTimeout(() => {
      const random = Math.round(Math.random() * 10);
      if (failMockEnabled && random === 1) {
        reject();
      } else {
        r(data);
      }
    }, 2000);
  });
};

// const getRewardLogisticsData = (queryObj) => {
//   return mockGet(rewardLogisticsData);
// };

const transactionData = {
  items: [
    {
      _id: "2123",
      loyaltyCardNumber: "3234234",
      createdOn: "2021-10-21T14:25:17.510Z",
      synchronizedOn: "2021-10-21T14:25:17.510Z",
      transactionType:TransactionTypes.COLLECTION,
      points:20,
      status:TransactionStatus.VALID,
      merchantLocationName:"Sunshine"
    },
    {
        _id: "2124",
        loyaltyCardNumber: "3234235",
        createdOn: "2021-10-21T14:25:17.510Z",
        synchronizedOn: "2021-10-21T14:25:17.510Z",
        transactionType:TransactionTypes.REDEMPTION,
        points:30,
        status:TransactionStatus.INVALID,
        merchantLocationName:"Bellavita"
      },
      {
        _id: "2125",
        loyaltyCardNumber: "3234236",
        createdOn: "2021-10-21T14:25:17.510Z",
        synchronizedOn: "2021-10-21T14:25:17.510Z",
        transactionType:TransactionTypes.ADJUSTMENT,
        points:40,
        status:TransactionStatus.VALID,
        merchantLocationName:"Jakartha"
      },
  ],
  totalCount: 30,
};

const  shopperLevelsData = {
  items: [
    {
      _id: "2123",
      affinityGroup:"Black JS",
      tier:"no tier",
      shopperLevel:11,
      loyaltyCardNumber: "3234234",
      updatedDate: "2021-10-21T14:25:17.510Z",
      synchronizedData: "2021-10-21T14:25:17.510Z",
      syncStatus:ShopperLevelStatus.FAILED,
    },
    {
      _id: "2123",
      affinityGroup:"Black JS",
      tier:"no tier",
      shopperLevel:11,
      loyaltyCardNumber: "3234234",
      updatedDate: "2021-10-21T14:25:17.510Z",
      synchronizedData: "2021-10-21T14:25:17.510Z",
      syncStatus:ShopperLevelStatus.SUCCESS,
    },
    {
      _id: "2123",
      affinityGroup:"Black JS",
      tier:"no tier",
      shopperLevel:11,
      loyaltyCardNumber: "3234234",
      updatedDate: "2021-10-21T14:25:17.510Z",
      synchronizedData: "2021-10-21T14:25:17.510Z",
      syncStatus:ShopperLevelStatus.FAILED,
    },
  ],
  totalCount: 30,
};

const  memberssData = {
  items: [
    {
      _id: "2123",
      memberCardNo:"0112345554",
      memberName:"Black JS",
      points:1,
      shopperLevel:11,
      updatedDate: "2021-10-21T14:25:17.510Z",
      synchronizedData: "2021-10-21T14:25:17.510Z",
      syncStatus:MemberStatus.FAILED,
    },
    {
      _id: "2123",
      memberCardNo:"0112345554",
      memberName:"Black JS",
      points:1,
      shopperLevel:11,
      updatedDate: "2021-10-21T14:25:17.510Z",
      synchronizedData: "2021-10-21T14:25:17.510Z",
      syncStatus:MemberStatus.DISCARDED,
    },
    {
      _id: "2123",
      memberCardNo:"0112345554",
      memberName:"Black JS",
      points:1,
      shopperLevel:11,
      updatedDate: "2021-10-21T14:25:17.510Z",
      synchronizedData: "2021-10-21T14:25:17.510Z",
      syncStatus:MemberStatus.SUCCESS,
    },
  ],
  totalCount: 30,
};
const getTransactions = (queryObj) => {
  //Query params : limit, skip, merchantId
  return mockGet(transactionData);
};

const getShopperLevel = (queryObj) => {
  return mockGet(shopperLevelsData);
};

const getMember= (queryObj) => {
  return mockGet(memberssData);
};
export { getTransactions ,getShopperLevel,getMember};
