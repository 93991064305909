const MemberStatus = {
    SUCCESS: "SUCCESS",
    RESOLVED: "RESOLVED",
    DISCARDED: "DISCARDED",
    FAILED: "FAILED",
    PENDING:"PENDING",
    all:""
};

export {
    MemberStatus
}
