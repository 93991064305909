import React, { useCallback } from "react";
import {
  SideNavigationBar,
} from "@shoutout-labs/shoutout-themes-enterprise";
import { useHistory } from "react-router-dom";
// import "./Navigation.scss";

const NavigationLink = ({ path, tab, icon, activePath, onclick }) => {
  const history = useHistory();
  const onClickTab = useCallback(() => {
    if (onclick) {
      onclick(path);
    } else {
      history.push(path);
    }
  }, [history, path, onclick]);
  return (
    <>
      {tab && <SideNavigationBar.NavLink
        active={activePath===path}
        icon={icon}
        id="side-nav-link-dashboard"
        onClick={onClickTab}
        name={tab}
      />
      }
    </>
  );
};

export default NavigationLink;
