import {
  BasicConfig,
  Utils as QbUtils,
  Widgets,
} from "@shoutout-labs/react-awesome-query-builder-shoutout";
const { VanillaTextWidget } = Widgets;
const InitialConfig = {
  ...BasicConfig,
  operators: {
    ...BasicConfig.operators,
    equal: {
      ...BasicConfig.operators.equal,
      label: "Equals",
    },
    not_equal: {
      ...BasicConfig.operators.not_equal,
      label: "Not equals",
    },
    like: {
      ...BasicConfig.operators.like,
      label: "Contains",
    },
    not_like: {
      ...BasicConfig.operators.not_like,
      label: "Not contains",
    },
    is_empty: {
      ...BasicConfig.operators.is_empty,
      label: "Is known",
    },
    is_not_empty: {
      ...BasicConfig.operators.is_not_empty,
      label: "Is unknown",
    },
    less: {
      ...BasicConfig.operators.less,
      label: "Less than",
    },
    less_or_equal: {
      ...BasicConfig.operators.less_or_equal,
      label: "Less than or equal",
    },
    greater: {
      ...BasicConfig.operators.greater,
      label: "Greater than",
    },
    greater_or_equal: {
      ...BasicConfig.operators.greater_or_equal,
      label: "Greater than or equal",
    },
    array_empty: {
      label: "Empty",
      reversedOp: "array_not_empty",
      labelForFormat: "NULL",
      cardinality: 0,
      formatOp: (field, _op, value, _valueSrc, _valueType, opDef) =>
        `${field} ${opDef.labelForFormat}`,
      mongoFormatOp: (field, op, value) => ({
        [field]: { $exist: true, $size: 0 },
      }),
    },
    array_not_empty: {
      label: "Not Empty",
      reversedOp: "array_empty",
      labelForFormat: "NOT NULL",
      cardinality: 0,
      formatOp: (field, _op, value, _valueSrc, _valueType, opDef) =>
        `${field} ${opDef.labelForFormat}`,
      mongoFormatOp: (field, op, value) => ({
        [field]: { $exist: true, $not: { $size: 0 } },
      }),
    },
  },
  widgets: {
    ...BasicConfig.widgets,
    text: {
      ...BasicConfig.widgets.text,
      factory: (props) => {
        return <VanillaTextWidget {...props} allowNew={true} clearButton />;
      },
    },
  },
  settings: {
    maxNesting: 1, //no groups
    maxNumberOfRules: 6,
    showErrorMessage: true,
    showNot: false,
  },
};

const buildMemberFilterConfig = (contactAttributes, tags) => {
  const attributes = Object.entries(contactAttributes).reduce(
    (result, [key, value]) => {
      switch (value.type) {
        case "string":
          if (value.values && value.values.length > 0) {
            result[key] = {
              label: value.label,
              type: "text",
              useAsyncSearch: true,
              useLoadMore: true,
              forceAsyncSearch: false,
              allowCustomValues: false,
              asyncFetch: (search) => {
                return {
                  values: value.values.map((item) => ({
                    title: item,
                    value: item,
                  })),
                  hasMore: false,
                };
              },
            }; //TODO: Suggestions are there to show up which can't show without updating the widget
          } else {
            result[key] = {
              label: value.label,
              type: "text",
            };
          }
          break;

        case "array": {
          result[key] = {
            label: value.label,
            type: "select",
            operators: [
              "select_any_in",
              "select_not_any_in",
              "array_empty",
              "array_not_empty",
            ],
          };

          break;
        }

        default: {
          result[key] = { label: value.label, type: value.type };
        }
      }

      return result;
    },
    {}
  );
  if (attributes.tags && tags) {
    attributes.tags.fieldSettings = {
      listValues: tags.map(({ label, tag_key }) => ({
        title: label,
        value: tag_key,
      })),
      showSearch: true,
    };
    attributes.tags.valueSources = ["value"];
  }

  return {
    ...InitialConfig,
    fields: attributes,
  };
};

const getMongoDBQuery = (filters, config) => {
  const filterData = QbUtils.checkTree(QbUtils.loadTree(filters), config);

  return QbUtils.mongodbFormat(filterData, config);
};
const generateUserAttribute = (projection) => {


  return projection.reduce((result, item) => {
    switch (item) {
      case "contact": {
        result.push("email");
        result.push("mobileNumber");
        break;
      }

      case "name": {
        result.push("firstName");
        result.push("lastName");
        result.push("loyaltyId");
        break;
      }

      default: {
        result.push(item);
      }
    }
    return result;
  }, []);

};
export { buildMemberFilterConfig, getMongoDBQuery, generateUserAttribute };
