import React, { useContext } from "react";
import { UserContext } from "./Contexts/userContext";
import { Route } from "react-router-dom";
import { LoadingComponent } from "./Components/utils/UtilComponents";
import SystemInitFailedMessage from "Components/system/initFailed";

const AuthRoute = ({ component: Component, routeName, ...rest }) => {
  const { isAuth, isRunningSystemInit, systemInitFailMessage } =
    useContext(UserContext);

  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuth || isRunningSystemInit ? (
          <LoadingComponent />
        ) : systemInitFailMessage ? (
          <SystemInitFailedMessage message={systemInitFailMessage} />
        ) : (
          <Component {...props} routeName={routeName} />
        )
      }
    />
  );
};

export default AuthRoute;
