import React from 'react'
import MyProfile from './MyProfile';

const MyProfilePage = () => {
    return (
        <div>
            <MyProfile/>
        </div>
    )
}

export default MyProfilePage
