import {fetchGet,jsonToQueryParam} from "./CommonServiceUtils";
import Constants from "../Constants";
import { AccessPermissionModules, AccessPermissionModuleNames } from "Data";
import { getPermissionPath } from "Utils";

const BASE_URL = `${Constants.BASE_URL}loyaltyservice/`;

const getAffinityGroups = ({ limit, skip, searchKey, regionId }) => {
    return fetchGet(
        `${BASE_URL}affinitygroups?${jsonToQueryParam({
            limit,
            skip,
            searchKey,
            regionId,
        })}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.AFFINITY_GROUPS,
            AccessPermissionModules[AccessPermissionModuleNames.AFFINITY_GROUPS]
                .actions.ListAffinityGroups
        )
    );
};

const getAllAffinityGroups=(args)=>{
    return new Promise(async (resolve, reject) => {
        const limit = 500;
        let totalCount = 0,
            affinityGroups = [];
        try {
            do {
                const affinityGroupsResponse = await getAffinityGroups({
                    limit,
                    skip: affinityGroups.length,
                    ...args,
                });
                totalCount = affinityGroupsResponse.total;
                affinityGroups = [
                    ...affinityGroups,
                    ...affinityGroupsResponse.items,
                ];
            } while (affinityGroups.length < totalCount);
            resolve(affinityGroups);
        } catch (e) {
            reject(e);
        }
    });
}
const getTiers = ({ limit = 100, skip = 0, regionId }) => {
    return fetchGet(
        `${BASE_URL}tiers?${jsonToQueryParam({
            limit,
            skip,
            regionId,
        })}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.TIER,
            AccessPermissionModules[AccessPermissionModuleNames.TIER].actions
                .ListTiers
        )
    );
};

const getMerchantList = ({
    limit = 100,
    skip = 0,
    regionId,
    searchKey,
    type,
    status,
}) => {
    return fetchGet(
        `${BASE_URL}merchants?${jsonToQueryParam({
            limit,
            skip: skip,
            regionId: regionId,
            searchKey,
            type,
            status,
        })}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.MERCHANT,
            AccessPermissionModules[AccessPermissionModuleNames.MERCHANT]
                .actions.ListMerchants
        )
    );
};

const getAllMerchants = args => {
    return new Promise(async (resolve, reject) => {
        const limit = 500;
        let totalCount = 0,
            merchants = [];
        try {
            do {
                const merchantResponse = await getMerchantList({
                    limit,
                    skip: merchants.length,
                    ...args,
                });
                totalCount = merchantResponse.total;
                merchants = [...merchants, ...merchantResponse.items];
            } while (merchants.length < totalCount);
            resolve(merchants);
        } catch (e) {
            reject(e);
        }
    });
};

const getMerchantLocations = ({ limit = 100, skip = 0, regionId, merchantId }) => {
    return fetchGet(
        `${BASE_URL}locations?${jsonToQueryParam({ limit, skip, regionId, merchantId })}`,
        true,
    );
};

const getAllMerchantLocations = args => {
    return new Promise(async (resolve, reject) => {
        const limit = 500;
        let totalCount = 0,
            merchantLocations = [];
        try {
            do {
                const merchantResponse = await getMerchantLocations({
                    limit,
                    skip: merchantLocations.length,
                    ...args,
                });
                totalCount = merchantResponse.total;
                merchantLocations = [...merchantLocations, ...merchantResponse.items];
            } while (merchantLocations.length < totalCount);
            resolve(merchantLocations);
        } catch (e) {
            reject(e);
        }
    });
};

const getSubTransactions = ({limit ,skip ,transactionType,withSystemSubTransactionTypes}) => {
    return fetchGet(`${BASE_URL}subtransactiontypes?${jsonToQueryParam(
        {limit 
            ,skip 
            ,transactionType,
            withSystemSubTransactionTypes
        }
        )}`);
};

export {
    getAllAffinityGroups,
    getTiers,
    getAllMerchants,
    getSubTransactions,
    getAllMerchantLocations
};
