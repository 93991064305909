import {  fetchGet, fetchPut,jsonToQueryParam } from "./CommonServiceUtils";
import { AccessPermissionModules, AccessPermissionModuleNames } from "Data";
import { getPermissionPath } from "Utils";
import Constants from "Constants";

const BASE_URL = `${Constants.SYNC_API_BASE_URL}datasyncservice/`;


const getAffinityTierMappings = (queryObj) => {
    return fetchGet(
      `${BASE_URL}affinitytiermappings?${jsonToQueryParam(queryObj)}`,
      true,
      getPermissionPath(
        AccessPermissionModuleNames.AFFINITY_TIER_MAPPING,
        AccessPermissionModules[AccessPermissionModuleNames.AFFINITY_TIER_MAPPING]
            .actions.ListAffinityTierMappings
      )
    );
}

const getAffinityTierMappingsById = ({id}) => {
    return fetchGet(
      `${BASE_URL}affinitytiermappings/${id}`, 
      true,
      getPermissionPath(
        AccessPermissionModuleNames.AFFINITY_TIER_MAPPING,
        AccessPermissionModules[AccessPermissionModuleNames.AFFINITY_TIER_MAPPING]
          .actions.GetAffinityTierMapping
      )
  );
};

const updateAffinityTierMappings = (id, payload) => {
    return fetchPut(
      `${BASE_URL}affinitytiermappings/${id}`, payload, 
      true,
      getPermissionPath(
        AccessPermissionModuleNames.AFFINITY_TIER_MAPPING,
        AccessPermissionModules[AccessPermissionModuleNames.AFFINITY_TIER_MAPPING]
          .actions.UpdateAffinityTierMapping
      )
    );
};

export { getAffinityTierMappings,
    getAffinityTierMappingsById,
    updateAffinityTierMappings
 };
