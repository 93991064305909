import React, { useCallback, useContext, useEffect, useReducer } from "react";
import { toast } from "react-toastify";
import { AccessPermissionModules, AccessPermissionModuleNames } from "Data";
import {
  getTiers,
  getAllAffinityGroups,
  getAllMerchants,
  getSubTransactions,
  getAllMerchantLocations, getSyncClusters
} from "Services";
import { UserContext } from "./userContext";

const DataContext = React.createContext();

const initialState = {
  isLoading: true,
  isLoadingTiers: false,
  tiers: [],
  merchants: [],
  affinityGroups: [],
  isLoadingAffinityGroups: false,
  subTransactions: [],
  isLoadingMerchantLocations: false,
  allMerchantLocations: [],
  syncClusters:[]
};

const DataContextActions = {
  GET_TIERS: "getTiers",
  SET_SYNC_CLUSTERS:"setSyncClusters",
  SET_LOADING_TIERS: "setLoadingTiers",
  SET_AFFINITY_GROUPS: "setAffinityGroups",
  SET_IS_LOADING_AFFINITY_GROUPS: "setIsLoadingAffinityGroups",
  SET_MERCHANTS: "setMerchants",
  SET_SUB_TRANSACTIONS: "setSubTransactions",
  SET_IS_LOADING_ALL_MERCHANT_LOCATIONS: "setIsLoadingAllMerchantLocations",
  SET_ALL_MERCHANT_LOCATIONS: "setAllMerchantLocations"
};

const reducer = (state, action) => {
  switch (action.type) {
    case DataContextActions.GET_TIERS: {
      return {
        ...state,
        tiers: action.tiers,

        isLoadingTiers: false,
      };
    }
    case DataContextActions.SET_MERCHANTS: {
      return {
        ...state,
        merchants: action.merchants,
      };
    }
    case DataContextActions.SET_LOADING_TIERS: {
      return {
        ...state,
        isLoadingTiers: action.status,
      };
    }
    case DataContextActions.SET_IS_LOADING_AFFINITY_GROUPS: {
      return {
        ...state,
        isLoadingAffinityGroups: action.status,
      };
    }
    case DataContextActions.SET_SUB_TRANSACTIONS: {
      return {
        ...state,
        subTransactions: action.subTransactions,
      };
    }
    case DataContextActions.SET_AFFINITY_GROUPS: {
      return {
        ...state,
        isLoadingAffinityGroups: false,
        affinityGroups: action.groups,
      };
    }
    case DataContextActions.SET_IS_LOADING_ALL_MERCHANT_LOCATIONS: {
      return {
        ...state,
        isLoadingMerchantLocations: action.status,
      };
    }
    case DataContextActions.SET_ALL_MERCHANT_LOCATIONS: {
      return {
        ...state,
        allMerchantLocations: action.merchantLocations,
      };
    }
    case DataContextActions.SET_SYNC_CLUSTERS: {
      return {
        ...state,
        syncClusters: action.syncClusters,
      };
    }
    default:
      return state;
  }
};

const DataContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {isAuth, regionId, isRunningSystemInit, selectedRegion, isAuthorizedForAction} = useContext(UserContext);

  const setTier = useCallback(newTier => {
    dispatch({ type: DataContextActions.GET_TIERS, config: newTier });
  }, [dispatch]);

  const loadSubTransactions = useCallback(async () => {
    try {
      const subTransactions = await getSubTransactions({
        limit:1000,
        skip:0 ,
        withSystemSubTransactionTypes:true
      })
      dispatch({ type: DataContextActions.SET_SUB_TRANSACTIONS, subTransactions: subTransactions.items});
    } catch (e) {
      console.error(e);
      toast.error(
        <div>
          Failed to load sub transaction data!
          <br/>
          {e.message && `Error: ${e.message}`}
        </div>
      );
    }
  }, [dispatch]);

  const loadTiers = useCallback(async () => {
    if (
      isAuthorizedForAction(
        AccessPermissionModuleNames.TIER,
        AccessPermissionModules[AccessPermissionModuleNames.TIER]
          .actions.ListTiers
      )
    ) {
      try {
        dispatch({ type: DataContextActions.SET_LOADING_TIERS, status: true });
        const tiersResponse = await getTiers({ regionId });
        dispatch({
          type: DataContextActions.GET_TIERS,
          tiers: tiersResponse.items || [],
        });
      } catch (e) {
        console.error(e);
        toast.error(
          <div>
            Failed to load tier data!
            <br/>
            {e.message && `Error: ${e.message}`}
          </div>
        );
        dispatch({ type: DataContextActions.SET_LOADING_TIERS, status: false });
      }
    } else {
      dispatch({
        type: DataContextActions.SET_LOADING_TIERS,
        status: false,
      });
    }
  }, [regionId, isAuthorizedForAction, dispatch]);

  const getSyncClustersData = useCallback(async () => {
        try {
          const syncClustersResponse = await getSyncClusters({limit:1000, skip:0, regionId});
          dispatch({type: DataContextActions.SET_SYNC_CLUSTERS, syncClusters:syncClustersResponse?.items.map((syncCluster)=>({label:syncCluster?.name,id:syncCluster?._id}))});
        } catch (error) {
          toast.error(error.message || "Could not load the sync cluster. Please try again.");
          console.error(error);
        }
      }, [regionId,dispatch]);


  const loadMerchants = useCallback(async () => {
    if (
      isAuthorizedForAction(
        AccessPermissionModuleNames.MERCHANT,
        AccessPermissionModules[
          AccessPermissionModuleNames.MERCHANT
        ].actions.ListMerchants
      )
    ) {
      try {
        const merchants = await getAllMerchants({ regionId });
        dispatch({
          type: DataContextActions.SET_MERCHANTS,
          merchants,
        });
      } catch (e) {
        console.error(e);
        toast.error(
          <div>
            Failed to load all merchant data!
            <br/>
            {e.message && `Error: ${e.message}`}
          </div>
        );
      }
    }
  }, [regionId, isAuthorizedForAction, dispatch]);

  const loadAffinityGroups = useCallback(async () => {
    if (
      isAuthorizedForAction(
          AccessPermissionModuleNames.AFFINITY_GROUPS,
          AccessPermissionModules[
              AccessPermissionModuleNames.AFFINITY_GROUPS
          ].actions.ListAffinityGroups
      )
    ) {
      try {
        dispatch({
          type: DataContextActions.SET_IS_LOADING_AFFINITY_GROUPS,
          status: true,
        });

        const affinityGroupResponse = await getAllAffinityGroups({
          regionId: selectedRegion._id,
        });
        dispatch({
          type: DataContextActions.SET_AFFINITY_GROUPS,
          groups: affinityGroupResponse,
          status: false,
        });
      } catch (e) {
        dispatch({
          type: DataContextActions.SET_IS_LOADING_AFFINITY_GROUPS,
          status: false,
        });
      }
    }
  }, [dispatch, selectedRegion, isAuthorizedForAction]);

  const loadAllMerchantLocations = useCallback(async () => {
      try {
        dispatch({ type: DataContextActions.SET_IS_LOADING_ALL_MERCHANT_LOCATIONS, status: true });
        
        const merchantLocations = await getAllMerchantLocations({ regionId });
        
        dispatch({ type: DataContextActions.SET_ALL_MERCHANT_LOCATIONS, merchantLocations });
      } catch (e) {
          console.error(e);
          toast.error(
            <div>
              Failed to load all merchant location data!
              <br/>
              {e.message && `Error: ${e.message}`}
            </div>
          );
      } finally {
        dispatch({ type: DataContextActions.SET_IS_LOADING_ALL_MERCHANT_LOCATIONS, status: false });
      }
  }, [regionId, dispatch]);

  useEffect(() => {
    if (isAuth && !isRunningSystemInit) {
      if (regionId) {
        loadTiers();
        loadAffinityGroups();
        loadMerchants();
        loadSubTransactions();
        loadAllMerchantLocations();
        getSyncClustersData();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth, isRunningSystemInit, regionId, /* loadTiers, loadAffinityGroups, loadMerchants, loadSubTransactions */]);

  const value = {
    ...state,
    setTier,
    loadTiers,
    loadAffinityGroups,
    loadAllMerchantLocations,
  };
  console.debug("Data Context: ", state);

  return (
    <DataContext.Provider value={value}>{props.children}</DataContext.Provider>
  );
};

const DataContextConsumer = DataContext.Consumer;

export {
  DataContext,
  DataContextProvider,
  DataContextConsumer,
  DataContextActions,
};
