import Constants from "Constants";
import { AccessPermissionModules, AccessPermissionModuleNames } from "Data";
import { getPermissionPath } from "Utils";
import {
  fetchGet,
  jsonToQueryParam,
  fetchPut,
  fetchPost,
} from "./CommonServiceUtils";

const BASE_URL = `${Constants.SYNC_API_BASE_URL}datasyncservice/`;

/*transactions endpoints*/
const getTransactionSyncLogs = (queryObj) => {
  return fetchGet(
    `${BASE_URL}transactionsynclogs?${jsonToQueryParam(queryObj)}`,
    true,
    getPermissionPath(
      AccessPermissionModuleNames.TRANSACTION_SYNC_LOG,
      AccessPermissionModules[AccessPermissionModuleNames.TRANSACTION_SYNC_LOG]
        .actions.ListTransactionSyncLogs
    )
  );
};

const getTransactionSyncLogsById = ({ id }) => {
  return fetchGet(
    `${BASE_URL}transactionsynclogs/${id}`,
    true,
    getPermissionPath(
      AccessPermissionModuleNames.TRANSACTION_SYNC_LOG,
      AccessPermissionModules[AccessPermissionModuleNames.TRANSACTION_SYNC_LOG]
        .actions.GetTransactionSyncLog
    )
  );
};

const editTransactionSyncLogs = ({ status, transactionSyncLogIds }) => {
  return fetchPut(
    `${BASE_URL}transactionsynclogs/bulk/update/`,
    {
      transactionSyncLogIds,
      status,
    },
    true,
    getPermissionPath(
      AccessPermissionModuleNames.TRANSACTION_SYNC_LOG,
      AccessPermissionModules[AccessPermissionModuleNames.TRANSACTION_SYNC_LOG]
        .actions.UpdateTransactionSyncLog
    )
  );
};

const editTransactionSyncLogById = ({ transactionSyncLogId, status, note }) => {
  return fetchPut(
    `${BASE_URL}transactionsynclogs/${transactionSyncLogId}`,
    {
      status,
      note,
    },
    true,
    getPermissionPath(
      AccessPermissionModuleNames.TRANSACTION_SYNC_LOG,
      AccessPermissionModules[AccessPermissionModuleNames.TRANSACTION_SYNC_LOG]
        .actions.UpdateTransactionSyncLog
    )
  );
};

const retryTransactionSyncLogs = ({ transactionSyncLogId }) => {
  return fetchPost(
    `${BASE_URL}transactionsynclogs/retry/${transactionSyncLogId}`
  );
};

const bulkRetryTransactionSyncLogs = (payload) => {
  // * Payload - regionId, retryTransactionIds
  return fetchPost(`${BASE_URL}transactionsynclogs/bulk/retry/`, payload);
};

/*Member endpoints */
const getMemberSyncLogs = (queryObj) => {
  return fetchGet(
    `${BASE_URL}membersynclogs?${jsonToQueryParam(queryObj)}`,
    true,
    getPermissionPath(
      AccessPermissionModuleNames.MEMBER_SYNC_LOG,
      AccessPermissionModules[AccessPermissionModuleNames.MEMBER_SYNC_LOG]
        .actions.ListMemberSyncLogs
    )
  );
};

const getMemberSyncLogsById = ({ id }) => {
  return fetchGet(
    `${BASE_URL}membersynclogs/${id}`,
    true,
    getPermissionPath(
      AccessPermissionModuleNames.MEMBER_SYNC_LOG,
      AccessPermissionModules[AccessPermissionModuleNames.MEMBER_SYNC_LOG]
        .actions.GetMemberSyncLog
    )
  );
};
const editMemberSyncLogs = ({ memberSyncLogIds, status }) => {
  return fetchPut(
    `${BASE_URL}membersynclogs/bulk/update/`,
    {
      memberSyncLogIds,
      status,
    },
    true,
    getPermissionPath(
      AccessPermissionModuleNames.MEMBER_SYNC_LOG,
      AccessPermissionModules[AccessPermissionModuleNames.MEMBER_SYNC_LOG]
        .actions.UpdateMemberSyncLog
    )
  );
};

const editMemberSyncLogById = ({ memberSyncLogId, status, note }) => {
  return fetchPut(
    `${BASE_URL}membersynclogs/${memberSyncLogId}`,
    {
      status,
      note,
    },
    true,
    getPermissionPath(
      AccessPermissionModuleNames.MEMBER_SYNC_LOG,
      AccessPermissionModules[AccessPermissionModuleNames.MEMBER_SYNC_LOG]
        .actions.UpdateMemberSyncLog
    )
  );
};

const retryMemberSyncLogById = ({ memberSyncLogId }) => {
  return fetchPost(`${BASE_URL}membersynclogs/retry/${memberSyncLogId}`);
};

const bulkRetryMemberSyncLogs = (payload) => {
  // * Payload - regionId, retryMemberSyncLogIds
  return fetchPost(`${BASE_URL}membersynclogs/bulk/retry`, payload);
};

/*Cards endpoints */
const getCardsSyncLogs = (queryObj) => {
  return fetchGet(
    `${BASE_URL}cardsynclogs?${jsonToQueryParam(queryObj)}`,
    true,
    getPermissionPath(
      AccessPermissionModuleNames.CARD_SYNC_LOG,
      AccessPermissionModules[AccessPermissionModuleNames.CARD_SYNC_LOG].actions
        .ListCardSyncLogs
    )
  );
};

const getCardsSyncLogsById = ({ id }) => {
  return fetchGet(
    `${BASE_URL}cardsynclogs/${id}`,
    true,
    getPermissionPath(
      AccessPermissionModuleNames.CARD_SYNC_LOG,
      AccessPermissionModules[AccessPermissionModuleNames.CARD_SYNC_LOG].actions
        .GetCardSyncLog
    )
  );
};
const editCardsSyncLogs = ({ syncLogIds, status }) => {
  return fetchPut(
    `${BASE_URL}cardsynclogs/bulk/update/`,
    {
      syncLogIds,
      status,
    },
    true,
    getPermissionPath(
      AccessPermissionModuleNames.CARD_SYNC_LOG,
      AccessPermissionModules[AccessPermissionModuleNames.CARD_SYNC_LOG].actions
        .UpdateCardSyncLog
    )
  );
};

const editCardsSyncLogById = ({ id, status, note }) => {
  return fetchPut(
    `${BASE_URL}cardsynclogs/${id}`,
    {
      status,
      note,
    },
    true,
    getPermissionPath(
      AccessPermissionModuleNames.CARD_SYNC_LOG,
      AccessPermissionModules[AccessPermissionModuleNames.CARD_SYNC_LOG].actions
        .UpdateCardSyncLog
    )
  );
};

const bulkRetryCardSyncLogs = (payload) => {
  // * Payload - regionId, retryCardSyncLogIds
  return fetchPost(`${BASE_URL}cardsynclogs/bulk/retry`, payload);
};

const retryCardsSyncLogsByID = ({ cardsSyncLogId }) => {
  return fetchPost(`${BASE_URL}cardsynclogs/retry/${cardsSyncLogId}`);
};

/*Sync Clusters endpoints */
const getSyncClusters = (queryObj) => {
  return fetchGet(
    `${BASE_URL}syncclusters?${jsonToQueryParam(queryObj)}`,
    true,
    getPermissionPath(
      AccessPermissionModuleNames.SYNC_CLUSTER,
      AccessPermissionModules[AccessPermissionModuleNames.SYNC_CLUSTER].actions
        .ListSyncClusters
    )
  );
};

const getSyncClusterByID = (id) => {
  return fetchGet(
    `${BASE_URL}syncclusters/${id}`,
    true,
    getPermissionPath(
      AccessPermissionModuleNames.SYNC_CLUSTER,
      AccessPermissionModules[AccessPermissionModuleNames.SYNC_CLUSTER].actions
        .GetSyncCluster
    )
  );
};

const updateSyncCluster = (id, payload) =>
  fetchPut(
    `${BASE_URL}syncclusters/${id}`,
    payload,
    true,
    getPermissionPath(
      AccessPermissionModuleNames.SYNC_CLUSTER,
      AccessPermissionModules[AccessPermissionModuleNames.SYNC_CLUSTER].actions
        .UpdateSyncCluster
    )
  );

const postSyncCluster = (payload) =>
  fetchPost(
    `${BASE_URL}syncclusters`,
    payload,
    true,
    getPermissionPath(
      AccessPermissionModuleNames.SYNC_CLUSTER,
      AccessPermissionModules[AccessPermissionModuleNames.SYNC_CLUSTER].actions
        .CreateSyncCluster
    )
  );

export {
  getTransactionSyncLogs,
  editTransactionSyncLogs,
  getMemberSyncLogs,
  editMemberSyncLogs,
  retryTransactionSyncLogs,
  bulkRetryTransactionSyncLogs,
  getTransactionSyncLogsById,
  editTransactionSyncLogById,
  getMemberSyncLogsById,
  editMemberSyncLogById,
  bulkRetryMemberSyncLogs,
  getCardsSyncLogsById,
  getCardsSyncLogs,
  editCardsSyncLogs,
  editCardsSyncLogById,
  bulkRetryCardSyncLogs,
  getSyncClusters,
  postSyncCluster,
  updateSyncCluster,
  getSyncClusterByID,
  retryCardsSyncLogsByID,
  retryMemberSyncLogById,
};
