// import UserProfilePage from 'Components/controlPanel/users/userProfile/UserProfilePage';
import React, { lazy, Suspense, useContext, useEffect } from "react";
import {
  Switch,
  Route,
  useLocation,
  useHistory,
  Redirect,
} from "react-router-dom";
//import MainNavigation from '../navigation/MainNavigation';
import Navigation from "../navigation/Navigation";
import { UserContext } from "Contexts";
import { isEmptyObject } from "Utils";
import qs from "qs";
import "./Home.css";
import MyProfilePage from "Components/myProfile/MyProfilePage";

const TransactionsPage = lazy(() => import("Components/transactions/TransactionsPage"));
const MembersPage = lazy(() => import("Components/members/MembersPage"));
const ConfigurationsPage = lazy(() => import("Components/configurations/ConfigurationsPage"));
const ShopperLevelsPage=lazy(() => import("Components/shopperLevels/shopperLevelsPage"));
const CardsPage=lazy(() => import("Components/cards/CardsPage"));
const ReportsPage = lazy(()=>import("Components/reports/ReportsPage"));
const Home = () => {
  const { selectedRegion } = useContext(UserContext);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (!isEmptyObject(selectedRegion)) {
      setTimeout(() => {
        history.replace({
          pathname: "/refresh",
          search: "?url=" + encodeURIComponent(location.pathname),
        });
      }, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRegion]);
  return (
    <>
      {/* <MainNavigation/> */}
      <div className="home-wrapper d-flex flex-row">
        <Navigation />
        <div className="flex-fill">
          {/* <div className="home-content flex-fill d-flex flex-column w-100"> */}
          <div className="home-content flex-fill d-flex flex-column">
            <Suspense fallback={<div>Loading...</div>}>
              <Switch>
                <Route
                  path="/refresh"
                  render={() => {
                    const url =
                      qs.parse(location.search, { ignoreQueryPrefix: true })
                        ?.url || "/";
                    return <Redirect to={url} />;
                  }}
                />
                  <Route name="Members" exact path="/members" component={MembersPage} />
                  <Route name="Members" exact path="/reports" component={ReportsPage} />
                  <Route name="Cards" exact path="/cards" component={CardsPage} />
                  <Route name="ShopperLevels" exact path="/shopper-levels" component={ShopperLevelsPage} />
                  <Route name="MyProfile" exact path="/profile" component={MyProfilePage} />
                  <Route name="Configurations" exact path="/configurations" component={ConfigurationsPage} />
                  <Route name="Transactions" exact path="/" component={TransactionsPage} />

              </Switch>
            </Suspense>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
